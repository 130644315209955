import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

const UnderWriterFlow = lazy(() => import("./pages/UwPortal/UnderWriterFlow"));
const NewQuote = lazy(() => import("./pages/Quote/NewQuote"));
const Login = lazy(() => import("./pages/Login/Login"));
const DashboardLibertateTab = lazy(() =>
  import("./pages/Dashboard/DashboardLibertateTab")
);
const DashboardSales = lazy(() =>
  import("./pages/DashboardSales/DashboardSales")
);
const DashboardRater = lazy(() =>
  import("./pages/RaterDashboard/DashboardRater")
);
const PrivateGroupRoute = lazy(() =>
  import("./components/PrivateGroupRoute/PrivateGroupRoute")
);
const NewRates = lazy(() => import("./pages/Rates/NewRates"));
const DataUpload = lazy(() => import("./pages/DataUpload/DataUpload"));
const RaterUpload = lazy(() => import("./pages/RaterUpload/RaterUpload"));
const RiskProfile = lazy(() =>
  import("./pages/RiskProfile/RiskProfile/RiskProfile")
);
const DemoRouteHandler = lazy(() => import("./pages/Demo/DemoRouteHandler"));

import { LibertateContextProvider } from "./context/ContextProvider";
import { Auth } from "aws-amplify";
import Loader from "./library/Loader/Loader";

function App(props) {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      (async () => {
        try {
          let sessionData = await Auth.currentAuthenticatedUser();
          setSessionData(sessionData);
        } catch (error) {}
        setLoading(false);
      })();
    }
  }, []);

  let salesLoginCheck = sessionData?.signInUserSession?.accessToken?.payload?.[
    "cognito:groups"
  ]?.includes(process.env.REACT_APP_SALESDASHBOARD_GROUP);

  return !loading ? (
    <LibertateContextProvider>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/"
              element={
                sessionData ? (
                  salesLoginCheck ? (
                    <Navigate to="/salesDashboard" />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (
                  <Login sessionData={sessionData} />
                )
              }
            />

            <Route path="/demo" element={<DemoRouteHandler />} />

            <Route path="/riskprofile" element={<RiskProfile />} />

            <Route
              path="/RaterUpload"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_RATER_GROUP]}
                  path="/RaterUpload"
                  sessionData={sessionData}
                >
                  <RaterUpload sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/Quote"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_GET_QUOTE_GROUP]}
                  sessionData={sessionData}
                >
                  <NewQuote sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/DataUpload"
              element={
                <PrivateGroupRoute
                  allowedGroups={[
                    process.env.REACT_APP_UPLOAD_COMPANY,
                    process.env.REACT_APP_UPLOAD_RATER,
                  ]}
                  sessionData={sessionData}
                >
                  <DataUpload sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/Dashboard"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                  sessionData={sessionData}
                >
                  <DashboardLibertateTab sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/salesDashboard"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_SALESDASHBOARD_GROUP]}
                  sessionData={sessionData}
                >
                  <DashboardSales sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/Rates"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_RATER_GROUP]}
                  sessionData={sessionData}
                >
                  <NewRates sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/UwPortal"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_UW_GROUPS]}
                  sessionData={sessionData}
                >
                  <UnderWriterFlow sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/RaterDashboard"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                  sessionData={sessionData}
                >
                  <DashboardRater sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
      <Toaster />
    </LibertateContextProvider>
  ) : (
    <></>
  );
}

export default App;
